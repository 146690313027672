<template>
  <v-main class="container-prosker">
    <v-row>
      <delete-warning
              :confirm-delete="confirmCancelSubscription"
              :on-close="onDeleteClose"
              :show-delete="showDelete"
              :loading="loading"
        />
      <v-col cols="12" class="text-center">
        <h2 class="text-prosk font-weight-bold my-5 secondary-prosk">{{ $t("planDetails") }}</h2>
      </v-col>
      <v-row v-if="skeletonLoader" class="mx-1">
          <v-col v-for="index in 1" :key="index" cols="12">
              <v-skeleton-loader
                      v-bind="skeletonAttrs"
                      type="list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
              ></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-col cols="12" v-if="!currentSubscription && !skeletonLoader">
        <v-list subheader>
          <v-list-item class="border-bottom">
            <v-list-item-content>
              <v-list-item-title class="mb-3 secondary-prosk">
                {{ $t("type") }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary-prosk">{{
                $t("free")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-bottom">
            <v-list-item-content>
              <v-list-item-title class="mb-3 secondary-prosk">
                {{ $t("cost") }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary-prosk">
                <money-format
                  value="0"
                  :locale="$i18n.locale"
                  :currency-code="currencyCode"
                  :supplemental-precision="0"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-3 secondary-prosk">
                {{ $t("yourPlanIncludes") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-for="(item, i) in itemsFree" :key="i" class="mb-2">
                  <v-icon
                    small
                    :color="item.included ? 'success' : 'red lighten-1'"
                    v-text="item.icon"
                  ></v-icon>
                  <span class="ml-2 secondary-prosk"> {{ $t(item.text) }} </span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="noPremiumSubscription()">
            <v-list-item-content>
              <v-list-item-title class="mb-3">
                {{ $t("actions") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-btn
                  @click="goToSubscriptions"
                  color="#59D79D"
                  class="white--text"
                >
                  {{ $t('youDontHaveAPlan') }}
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="hasActionsEnabled()">
            <v-list-item-content>
              <v-list-item-title class="mb-3">
                {{ $t("actions") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="canPauseOrCancel()">
                  <v-row>
                    <v-col>
                  <general-button
                   size="medium"
                   cssClasses="w-100"
                    secondary
                    :action="pauseSubscription"
                    :loading="false"
                  >
                  Pausar suscripción
                  </general-button>
                </v-col>
                <v-col>
                  <general-button
                  cssClasses="w-100"
                  size="medium"
                    outlined
                    :action="cancelSubscription"
                    :loading="false"
                  >
                  Cancelar suscripción
                  </general-button>
                </v-col>
                </v-row>
                </div>
                <div v-if="canActivateOrCancel()">
                  <p>{{ $t('activatePausedSubscription') }}</p>
                  <v-row>
                    <v-col>
                  <general-button
                   size="medium"
                   cssClasses="w-100"
                    secondary
                    :action="activateSubscription"
                    :loading="false"
                  >
                  Activar suscripción
                  </general-button>
                </v-col>
                <v-col>
                  <general-button
                  cssClasses="w-100"
                  size="medium"
                    outlined
                    :action="cancelSubscription"
                    :loading="false"
                  >
                  Cancelar suscripción
                  </general-button>
                </v-col>
                </v-row>
                </div>
              </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="currentSubscription && currentSubscription.expiry_date">
            <v-list-item-content>
              <v-list-item-title class="mb-3">
                {{ $t("expirationDate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <p>{{ getFormattedDate(currentSubscription && currentSubscription.expiry_date) }}</p>
                <alert :message="$t('expirationNotice')"/>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="justify-content-center mt-5">
          <alert class="round" :message="$t('youDontHaveAPlan')"/>
        </v-row>
        <v-row class="justify-content-center">
          <v-btn
            @click="goToSubscriptions"
            color="#59D79D"
            class="white--text float-end mt-3 round"
          >
            {{ $t("goToSubscriptions") }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" md="9" class="mx-auto my-0 py-0" :style="{maxWidth:'600px'}" v-if="!skeletonLoader && currentSubscription">
        <v-list subheader class="border-radius-medium p-5">
          <v-list-item class="border-bottom">
            <v-list-item-content>
              <v-list-item-title class="mb-3 smain_text_color--text ">
                {{ $t("type") }}
              </v-list-item-title>
              <v-list-item-subtitle class="main_text_color--text font-weight-bold text-h6">{{
                currentSubscription.subscription.name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-bottom">
            <v-list-item-content>
              <v-list-item-title class="mb-3 main_text_color--text ">
                {{ $t("cost") }}
              </v-list-item-title>
              <v-list-item-subtitle class="main_text_color--text font-weight-bold text-h6">
                <money-format
                  :value="currentSubscription.subscription.cost"
                  :locale="$i18n.locale"
                  :currency-code="currencyCode"
                  :supplemental-precision="0"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-3 main_text_color--text ">
                {{ $t("yourPlanIncludes") }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="hasPremiumFeatures(currentSubscription.subscription)"
                class="mb-2 secondary-prosk"
              >
                <div v-for="(item, i) in itemsPremium" :key="i" class="d-flex align-center mb-2">
                  <img src='@/assets/images/page-img/Check-green-black.svg' v-if="item.included"/>
                  <v-icon
                    small
                    :color="'red lighten-1'"
                    v-text="item.icon"
                    v-if="!item.included"
                  ></v-icon>
                  <span class="mt-1 ml-2 main_text_color--text "> {{ $t(item.text) }} </span>
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                <div v-for="(item, i) in itemsFree" :key="i" class="d-flex align-center mb-2">
                  <img src='@/assets/images/page-img/Check-green-black.svg' v-if="item.included"/>
                  <v-icon
                    small
                    :color="'red lighten-1'"
                    v-text="item.icon"
                    v-if="!item.included"
                  ></v-icon>
                  <span class="ml-2 main_text_color--text "> {{ $t(item.text) }} </span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="currentSubscription && currentSubscription.subscription.name === 'Premium'">
            <v-list-item-content>
              <v-list-item-title class="mb-3 main_text_color--text font-weight-bold">
                {{ $t("nextPaymentDate") }}
              </v-list-item-title>
              <v-list-item-subtitle class="main_text_color--text">
                {{
                  getFormattedDate(currentSubscription && currentSubscription.start_date, currentSubscription && currentSubscription.subscription.period)
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="currentSubscription.expiry_date">
            <v-list-item-content>
              <v-list-item-title class="mb-3 main_text_color--text font-weight-bold">
                {{ $t("expirationDate") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <p class="main_text_color--text">{{ getFormattedDate(currentSubscription.expiry_date) }}</p>
                <alert :message="$t('expirationNotice')"/>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="noPremiumSubscription()">
            <v-list-item-content>
              <!-- <v-list-item-title class="mb-3 secondary-prosk">
                {{ $t("actions") }}
              </v-list-item-title> -->
              <v-list-item-subtitle class="secondary-prosk">
                <v-btn
                  @click="goToSubscriptions"
                  color="#59D79D"
                  class="secondary-prosk"
                >
                  {{ $t('youDontHaveAPlan') }}
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="border-top" v-if="hasActionsEnabled()">
            <v-list-item-content>
              <v-list-item-subtitle>
                <div v-if="canPauseOrCancel()">
                  <v-row>
                    <v-col>
                  <general-button
                   size="medium"
                   cssClasses="w-100"
                    secondary
                    :action="pauseSubscription"
                    :loading="false"
                  >
                  Pausar suscripción
                  </general-button>
                </v-col>
                <v-col>
                  <general-button
                  cssClasses="w-100"
                  size="medium"
                    outlined
                    :action="cancelSubscription"
                    :loading="false"
                  >
                  Cancelar suscripción
                  </general-button>
                </v-col>
                </v-row>
                </div>
                <div v-if="canActivateOrCancel()">
                  <p>{{ $t('activatePausedSubscription') }}</p>
                  <v-row>
                    <v-col>
                  <general-button
                  cssClasses="w-100"
                  size="medium"
                    primary
                    :action="activateSubscription"
                    :loading="false"
                  >
                  Activar suscripción
                  </general-button>
                </v-col>
                <v-col>
                  <general-button
                  cssClasses="w-100"
                  size="medium"
                    outlined
                    :action="()=>cancelSubscription"
                    :loading="false"
                  >
                  Cancelar suscripción
                  </general-button>
                </v-col>
                </v-row>
                </div>
              </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
import MoneyFormat from 'vue-money-format';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

export default {
  name: 'UserSubscriptions',
  components: { Alert, DeleteWarning, MoneyFormat, GeneralButton },
  mixins: [ errorDialog, userDataMixin ],
  data () {
    return {
      currencyCode: '',
      skeletonLoader: false,
      listLoader: false,
      skeletonAttrs: {
          class: 'mb-6',
          boilerplate: true,
          elevation: 2
      },
      currentSubscription: null,
      pausedSubscription: null,
      subscriptionList: [],
      itemsPremium: [
        {
          text: 'publicProfile',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'internalPublications',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'positioning',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'virtualDiary',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'paymentGateway',
          icon: 'mdi-check-circle-outline',
          included: true
        }
      ],
      itemsFree: [
        {
          text: 'publicProfile',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'internalPublications',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'positioning',
          icon: 'mdi-close-circle-outline',
          included: false
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'virtualDiary',
          icon: 'mdi-close-circle-outline',
          included: false
        },
        {
          text: 'paymentGateway',
          icon: 'mdi-check-circle-outline',
          included: true
        }
      ],
      status: 20,
      loading: false,
      itemToDelete: '',
      showDelete: false
    };
  },
  created () {
    this.getCurrentSubscription();
    this.list();
    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
  },
  computed: {
    headers () {
      return [
        { text: this.$t('period'), value: 'period' },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('startDate'), value: 'start_date' },
        { text: this.$t('endDate'), value: 'end_date' },
        { text: this.$t('subscription'), value: 'subscription.name' },
        { text: this.$t('price'), value: 'subscription.cost' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ];
    },
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onDeleteClose () {
      this.showDelete = false;
    },
    pauseSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/subscriptions`;
      const data = {
        status: 1
      };
      axios.patch(url, data).then(() => {
        this.$swal.fire({
          title: this.$t('success'),
          text: this.$t('subscriptionPaused')
        });
        this.getCurrentSubscription();
        this.list();
      });
    },
    activateSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/subscriptions`;
      const data = {
        status: 0
      };
      axios.patch(url, data).then(() => {
        this.$swal.fire({
          title: this.$t('success'),
          text: this.$t('resumedSubscription')
        });
        this.getCurrentSubscription();
        this.list();
      });
    },
    cancelSubscription () {
      this.showDelete = true;
    },
    confirmCancelSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/subscriptions`;
      const data = {
        status: 2
      };
      axios.patch(url, data)
      .then(() => {
        this.$swal.fire({
          title: this.$t('success'),
          text: this.$t('subscriptionCancelled')
        })
        this.getCurrentSubscription();
        this.onDeleteClose();
        this.list();
      })
      .catch((error) => {
        this.onDeleteClose();
        this.showError(error);
      });
    },
    goToSubscriptions () {
      this.$router.push({ name: 'social.subscription-page' });
    },
    async list (page = 1) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/subscriptions?page=${page}`;
      this.listLoader = true;
      await axios.get(url).then(({ data }) => {
        this.subscriptionList = data.data.userSubscriptions;
        this.listLoader = false;
      });
    },
    getStatusName (status) {
      if (status === 0) {
        return this.$t('active');
      } else if (status === 1) {
        return this.$t('paused');
      } else if (status === 2) {
        return this.$t('cancelled');
      }
    },
    getPeriodName (period) {
      if (period === 1) {
        return this.$t('monthly');
      } else if (period === 4) {
        return this.$t('quarterly');
      } else if (period === 6) {
        return this.$t('halfYearly');
      } else if (period === 12) {
        return this.$t('annual');
      }
    },
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    getEndDateFormat (date, period, status) {
      const originalDate = new Date(date);
        if (period === 1 && status === 2) {
            return moment(moment.utc(originalDate).add(1, 'months')).format(
                'DD-MM-YYYY'
            );
        } else if (period === 4 && status === 2) {
            return moment(moment.utc(originalDate).add(4, 'months')).format(
                'DD-MM-YYYY'
            );
        } else if (period === 6 && status === 2) {
            return moment(moment.utc(originalDate).add(6, 'months')).format(
                'DD-MM-YYYY'
            );
        } else if (period === 12 && status === 2) {
            return moment(moment.utc(originalDate).add(12, 'months')).format(
                'DD-MM-YYYY'
            );
        } else {
            return this.$t('N/A');
        }
    },
    getFormattedDate (date, period) {
      const originalDate = new Date(date);
      let formattedDate = moment(moment.utc(originalDate));
      if (period) {
        formattedDate.add(period, 'months');
      }
      return formattedDate.format('DD-MM-YYYY');
    },
    hasActionsEnabled () {
      return (this.currentSubscription && this.currentSubscription.subscription.name === 'Premium') || (this.pausedSubscription && this.pausedSubscription.subscription.name === 'Premium');
    },
    canPauseOrCancel () {
      return this.currentSubscription && this.currentSubscription.subscription.name === 'Premium' && this.currentSubscription.status === 0;
    },
    canActivateOrCancel () {
      return this.currentSubscription.subscription.name === 'Free' && this.pausedSubscription && this.pausedSubscription.subscription.name === 'Premium';
    },
    hasPremiumFeatures (subscription) {
      return subscription.name === 'Free Trial' || subscription.name === 'Premium';
    },
    noPremiumSubscription () {
      return this.currentSubscription && this.currentSubscription.subscription.name === 'Free' && !this.pausedSubscription;
    },
    getCurrentSubscription () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/subscriptions`;
      this.currentSubscription = null;
      this.pausedSubscription = null;
      this.skeletonLoader = true;
      axios.get(url).then((response) => {
        this.currentSubscription = response.data.data.userSubscriptions.find(item => {
          return (item.status === 0) && // TODO - const for active
            moment(item.start_date).isSameOrBefore(moment()) &&
            (!item.expiry_date || moment(item.expiry_date).isSameOrAfter(moment()));
        });

        this.pausedSubscription = response.data.data.userSubscriptions.find(item => {
          return item.status === 1 &&// TODO - const for paused
            moment(item.start_date).isSameOrBefore(moment()) &&
            (!item.expiry_date || moment(item.expiry_date).isSameOrAfter(moment()));
        });
        this.skeletonLoader = false;
        this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: 'subscription',
        subscriptionType: this.currentSubscription?.subscription?.name || 'Free'
      });
      });
    },
    sendDataLayerInfo () {
      this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'subscription',
      subscriptionType: this.currentSubscription?.subscription?.name || 'Free'
    });
    }
  }
};
</script>
<style scoped>
  .round {
    border-radius: 100px;
  }
</style>
