<template>
    <v-dialog
        v-model="show"
        width="300"
    >
      <template v-slot:activator="{ on }">
        <v-btn class="button" icon v-on="on">
          <prosk-icon icon="compartir" />
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary white--text">
            <span class="headline">
                Share
                <v-icon  class="white--text ">mdi-share-variant-outline</v-icon>
            </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
                <social-media-button :onClick="shareOnFacebook" socialIcon="facebook" size="block" />
                <social-media-button :onClick="shareOnTwitter" socialIcon="twitter" size="block" cssClasses="white--text mt-2"/>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import errorDialog from '@/components/socialvue/mixins/errorDialog';
  import SocialMediaButton from '@/components/socialvue/buttons/SocialMediaButton.vue';
  import ProskIcon from '@/icons/ProskIcon.vue';

  export default {
    name: 'ShareDialog',
    components: { ProskIcon, SocialMediaButton },
    props: ['show', 'networks', 'sharing', 'buttonSize'],
    mixins: [errorDialog],
    data () {
      return {
        loading: false,
        skill_name: null,
        skills: [],
        nameError: []
      };
    },
    methods: {
    shareOnFacebook () {
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.sharing)}`;
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    },
    shareOnTwitter () {
      const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.sharing)}`;
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
 }
  };
  </script>
<style scoped>
.button {
  background-color: #3F44A612;
  height: 40px;
  padding: 9px;
  width: 40px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
