<template>
    <v-btn
      @click="onClick"
      :small="!size || size === 'small'"
      :large="size === 'large'"
      :x-large="size === 'x-large'"
      :block="size === 'block'"
      :loading="loading"
      :color="color ? color: '#59D79D'"
      :class="cssClasses ? cssClasses : 'white--text'"
    >
    <div class="d-flex align-center">
      <v-icon v-if="socialIcon==='facebook'" color="#3F44A6">mdi-facebook</v-icon>
      <span v-if="socialIcon==='facebook'"> Facebook</span>

      <v-icon v-if="socialIcon==='twitter'" color="#1DA1F2">mdi-twitter</v-icon>
      <span v-if="socialIcon==='twitter'"> Twitter</span>
    </div>
    </v-btn>
  </template>
  <script>
  export default {
    name: 'SocialMediaButton',
    props: ['onClick', 'loading', 'socialIcon', 'size', 'color', 'cssClasses']
  };
  </script>
