<template>
  <div :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">
    <prosk-title v-if="hasTitle">
      <slot name="title" ></slot>
    </prosk-title>
    <v-card v-if="hasCard" elevation="0" class="details-card" :class="cardClass">
      <slot></slot>
      <v-card-text v-if="hasContent">
        <slot name="content" ></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProskTitle from '@/components/socialvue/markup/ProskTitle.vue';

export default {
  name: 'DetailsCard',
  components: {
    ProskTitle
  },
  props: {
    cardClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasTitle () {
      return !!this.$slots.title;
    },
    hasCard () {
      return !!this.$slots.default || !!this.$slots.content;
    },
    hasContent () {
      return !!this.$slots.content;
    }
  }
};
</script>
<style scoped>
  .details-card {
    border-radius: 32px;
    margin-bottom: 40px;
    box-shadow: 0px 3px 50px 0px #3F44A612 !important;
  }

  .details-card >>> .v-card__text {
    padding: 40px;
  }
</style>
<style lang="scss" scoped>
  .sm {
    .details-card .v-card__text {
      padding: 24px 32px;
    }
  }
</style>
