<template>
  <v-main class="container-prosker" :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">
    <v-card class="banner-wrapper">
      <v-card-text class="p-0">
        <div class="position-relative">
          <div :class="$vuetify.breakpoint.mobile ? 'bg-200' : 'bg-275'" class="bg-cover banner"
            :style="{
             'background-image': 'url(' + ((userData.banner_img) ? userData.banner_img : defaultImgPath) + ')',
            }"></div>
          <div class="position-absolute bottom-0 right-0" v-if="isMyProfile">
            <dialog-user-banner :on-close="onBannerDialogClose" :new-item="newBanner" :show-add="showDialogUserBanner"/>
          </div>
        </div>

        <v-row class="profile-info">
          <div class="userContainer">
            <div :class="userData.profile_img ? '' : 'defaultImageUser'" @mouseout="hideButton"
                  class="userPicture rounded-circle overflow-hidden" @mouseover="showButton"
                  :style="(userData.profile_img) ? { 'background-image': 'url(' + userData.profile_img + ')' } : {}">
              <div ref="buttonCamera" class="d-flex justify-content-center align-items-center w-100 h-100"
                    v-if="isMyProfile">
                <user-profile-image-dialog :on-close="onAddImageClose" :new-item="newProfileImage"
                                            :show-add="showAddImage"/>
              </div>
            </div>
          </div>

          <div class="profile-name">
            <h2 class="font-weight-bold secondary--text same-line">
              {{ userData.first_name }} {{ userData.last_name }}
            </h2>
            <rating v-model="rating" readonly />
          </div>

          <div class="profile-work" v-show="(currentUser && currentUser.type_user) >= $options.USER_TYPE_PROSKER">
            <h5 class="azul_grey--text d-flex">
              <prosk-icon icon="trabajos" />
              <p class="m-0 ml-2">{{ userData.count_works_done }} {{ $t('worksDone') }}</p>
            </h5>
          </div>
          <div class="profile-share">
            <share-dialog
                :show=false
                :networks="socialNetWorks.networks"
                :sharing="currentUrl"
            />
          </div>

          <!-- <v-col cols="12" md="3" class="text-center mt-10" v-if="currentUser && !isMyProfile">
            <div class="mb-2">
              <v-btn icon @click="toggleFavorite(userData.id)">
                <v-icon :color="is_user_favorite ? '#7EBE72' : 'white'" class="mr-2">
                  {{ is_user_favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
                <span class="white--text">{{ is_user_favorite ? $t('favorites') : $t('addToFavorite') }}</span>
              </v-btn>
            </div>
            <v-btn color="#59D79D" @click="createConversation(userData.id, userData.username)"
                  class="white--text" small>
              <v-icon small>mdi-email-outline</v-icon>
              {{ $t("message") }}
            </v-btn>
            <share-dialog
                :show=false
                :networks="socialNetWorks.networks"
                :sharing="currentUrl"
            />
          </v-col> -->

          <!-- <v-col>
            <div class="d-flex justify-content-end align-center h-100">
                <share-dialog
                  :show=false
                  :networks="socialNetWorks.networks"
                  :sharing="currentUrl"
              />
              </div>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs width="100%" hide-slider v-model="tabIndex" show-arrows
            class="my-8 pb-8" background-color="transparent">
      <v-tab v-for="tab in tabs" :key="tab.key" @click="changeTab(tab.index)"
             :class="[{'active-tab title_highlight_color': activeTab === tab.index }]"
             class="round tab"
            >
        {{ $t(tab.title) }}
      </v-tab>
      <v-tabs-items v-model="tabIndex" ref="tabsSection" class="tab-items-container">
        <v-tab-item v-for="tab in tabs" :key="tab.key">
          <user-details ref="userDetailsComponent" v-if="tab.index === $options.PROFILE_TAB" :current-user="userData"
            :is-searched-user="!isMyProfile" :visitor-user="currentUser" />
          <user-reviews  ref="userReviewsComponent" v-if="tab.index === $options.REVIEWS_TAB" :current-user="userData"
            :is-searched-user="!isMyProfile"/>
          <user-preferences  ref="userPreferencesComponent" v-if="tab.index === $options.PREFERENCES_TAB"/>
          <user-services ref="userServicesComponent" v-if="tab.index === $options.SERVICES_TAB" :current-user="userData"
            :is-searched-user="!isMyProfile" :is-my-services="true" />
          <user-services ref="userOrdersComponent" v-if="tab.index === $options.ORDERS_TAB" :current-user="userData"
                        :is-searched-user="!isMyProfile"/>
          <Chat ref="chatComponent" v-if="tab.index === $options.CHAT_TAB" :current-user="userData"
                :is-searched-user="!isMyProfile"/>
          <user-calendar ref="userCalendarComponent" v-if="tab.index === $options.CALENDAR_TAB && userData.type_user > $options.USER_TYPE_CLIENT"
            :current-user="userData" :is-searched-user="!isMyProfile"/>
          <user-calendar-client ref="userCalendarClientComponent" v-if="tab.index === $options.CALENDAR_TAB && userData.type_user === $options.USER_TYPE_CLIENT"
            :current-user="userData" />
          <user-subscriptions ref="userSubscriptionsComponent" v-if="tab.index === $options.SUBSCRIPTION_TAB" :current-user="userData"
            :is-searched-user="!isMyProfile"/>
          <contact-page ref="contactPageComponent" v-if="tab.index === $options.CONTACT_TAB" :current-user="userData"
            :is-searched-user="!isMyProfile"/>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

  </v-main>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import UserProfileImageDialog from '@/components/socialvue/dialogs/UserProfileImageDialog';
import CryptoJS from 'crypto-js';

import DialogUserBanner from '@/components/socialvue/dialogs/DialogUserBanner';
import UserDetails from '@/views/User/UserDetails';
import UserReviews from '@/views/User/UserReviews';
import UserPreferences from '@/views/User/UserPreferences';
import UserCalendar from '@/views/User/UserCalendar';
import UserCalendarClient from '@/views/User/UserCalendarClient';
import UserServices from '@/views/User/UserServices';
import Chat from '../Apps/Chat/Chat';
import UserSubscriptions from '../Pages/UserSubscriptions';
import ContactPage from '../Pages/ContactPage';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import ShareDialog from '@/components/socialvue/dialogs/ShareDialog.vue';
import Rating from '@/components/socialvue/profile/rating.vue';
import ProskIcon from '../../icons/ProskIcon.vue';

import {
  PROFILE_TAB,
  REVIEWS_TAB,
  SERVICES_TAB,
  ORDERS_TAB,
  CHAT_TAB,
  PREFERENCES_TAB,
  SUBSCRIPTION_TAB,
  CONTACT_TAB,
  CALENDAR_TAB,
  USER_TYPE_PROSKER,
  USER_TYPE_CLIENT,
  FEATURE_JOBS,
  AES_SECRET
} from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import axios from 'axios';

export default {
  name: 'UserProfile',
  // layout: 'LayoutGray',
  metaInfo () {
    return {
    meta: [
      { property: 'og:title', content: this.userData.first_name + ' ' + this.userData.last_name },
      { property: 'og:description', content: this.userData.description },
      { property: 'og:image', content: this.userData.profile_img },
      { property: 'og:url', content: window.location.href }
    ]
    }
  },
  components: {
    UserDetails,
    UserReviews,
    UserPreferences,
    UserCalendar,
    UserCalendarClient,
    UserServices,
    Chat,
    UserSubscriptions,
    ContactPage,
    DialogUserBanner,
    UserProfileImageDialog,
    ShareDialog,
    ProskIcon,
    Rating
  },
  mixins: [errorDialog],
  data () {
    return {
      reviews: [],
      reviewsDetail: [],
      userSearchedObject: {},
      isPremiumUser: false,
      title: '',
      url_image: null,
      content: '',
      url_video: null,
      posts: [],
      activeTab: null,
      tabs: [],
      is_user_favorite: false,
      contentErrors: [],
      missingData: [],
      rating: 5,
      loadingUserCategory: false,
      loadingUserSkill: false,
      url: null,
      loading: false,
      showDialogUserBanner: false,
      showAdd: false,
      showAddCategory: false,
      showBanDialog: false,
      tabIndex: null,
      showAddSkill: false,
      addToFavorites: false,
      dialogBannerPhoto: false,
      dialogUserPhoto: false,
      userIds: [],
      userData: {},
      showMPButton: false,
      name: '',
      page: 1,
      showAddImage: false,
      defaultImgPath: require('@/assets/images/page-img/bannerUserProfile.png'),
      socialNetWorks: {
          sharing: {
          url: this.$route.fullPath
        },
      networks: [
          { network: 'facebook', name: 'Facebook' },
          { network: 'twitter', name: 'Twitter' }
      ]
    }
    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    },
    currentUrl () {
      return `${window.location.origin}${this.$route.fullPath}`;
    }
  },
  watch: {
    currentUser: function (value) {
      if (value) {
        this.getUserSearched();
      }
    },
    $route (to, from) {
      if (from.path !== to.path) {
        this.initComponent();
      }
      const selectedTab = this.$route.query.selectedTab;
      if (selectedTab === this.$options.CALENDAR_TAB) {
        this.tabIndex = this.tabs.findIndex(tab => tab.index === selectedTab);
        this.changeTab(this.tabIndex);
        this.scrollToDetails();
      }
    }
  },
  created () {
    this.setImportedConstants();
    this.initComponent();
  },
  mounted () {
    this.hideButton();
    this.scrollToDetails();
  },
  methods: {
    initComponent () {
      if (this.$route.query.selectedTab) {
        this.userSearchedObject.selectedTab = this.$route.query.selectedTab;
      }

      const userUrlData = this.$route.params.query.split('-');
      if (userUrlData.length < 2) {
        // throw error
      } else {
        const hashedUserId = userUrlData[1];
        const bytes = CryptoJS.AES.decrypt(hashedUserId, this.$options.AES_SECRET);
        this.userSearchedObject.id = bytes.toString(CryptoJS.enc.Utf8);
      }

      this.isMyProfile = (this.currentUser && parseInt(this.currentUser.id) === parseInt(this.userSearchedObject.id));

      this.getUserSearched(() => {
        this.getPostsData();
        this.setTabs();
        this.getReviews();
      });
    },
    getReviews () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/reviews?filters=jobs.prosker_user_id=${this.userSearchedId}|`;
      this.reviews.splice(0, this.reviews.length);
      axios.get(url).then((response) => {
        response.data.data.forEach((review) => {
          this.reviews.push(review);
          this.reviewsDetail.push(review);
        });
        this.reviewsDetail.splice(5, 1);
      }).catch((error) => {
        this.showError(error);
      });
    },
    getConversation (recipientId, senderId, callback) {
      const metadata = [recipientId, senderId].sort((a, b) => {
        if (a >= b) {
          return 1;
        }
        return -1;
      }).join(',');
      // TODO - improve conversation filtering!
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations?search=${metadata}`;
      axios.get(url).then((response) => {
        if (!response.data.data.length) {
          throw new Error(this.$t('errorLoadingConversation'));
        } else {
          callback(response.data.data[0].id);
        }
      }).catch((error) => {
        this.showError(error);
      });
    },
    getPostHour (hour) {
      const originalDate = new Date(hour);
      return moment(moment.utc(originalDate)).format('DD/MM/YYYY HH:mm');
    },
    getPostsData () {
      this.posts = [];
      const url = `${process.env.VUE_APP_BASE_URL}/api/posts?filters=user_id=${this.userSearchedObject.id}`;
      axios.get(url).then((response) => {
        response.data.data.forEach((post) => {
          post.userData = this.userData;
          this.posts.push(post);
        });
      }).catch((error) => {
        this.showError(error);
      });
    },
    banUser () {
      this.showBanDialog = true;
    },
    createConversation (userId) {
      if (!this.currentUser) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/conversations`;
        this.name = this.userData.username;
        this.userIds = [userId, this.currentUser.id];
        const data = {
          name: this.name,
          user_ids: this.userIds
        };
        axios.post(url, data).then((res) => {
          this.$router.push({
            name: 'app.chat',
            params: { conversationId: res.data.data[0].id.toString() }
          });
        }).catch((error) => {
          if (error.response.data.errors.user_ids && error.response.data.errors.user_ids[0] === 'conversationAlreadyCreated') {
            this.getConversation(
                userId,
                this.currentUser.id,
                (conversationId) => {
                  this.$router.push({
                    name: 'app.chat',
                    params: { conversationId: conversationId.toString() }
                  });
                }
            )
          } else {
            this.showError(error);
          }
        });
      }
    },
    getUserSearched (callback) {
      if (!this.isMyProfile) {
        const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.userSearchedObject.id}`;
        this.is_user_favorite = false;
        axios.get(url).then((response) => {
          this.userData = response.data.data;
          // //
          //     this.metaInfo.meta = [
          //     { property: 'og:title', content: 'test' },
          //     { property: 'og:description', content: 'test' },
          //     { property: 'og:image', content: this.userData.banner_img ? this.userData.banner_img : this.defaultImgPath },
          //     { property: 'og:url', content: this.window.location.href }
          //   ]

          //   // Atualize as metatags
          //   this.$meta().refresh()
          // //
          this.isPremiumUser = response.data.data.active_subscription.filter(item => item.name === 'Premium').length > 0;
          if (this.userData.favorites.length) {
            this.userData.favorites.forEach((favorite) => {
              if (this.currentUser && favorite.user_id === this.currentUser.id) {
                this.is_user_favorite = true;
              }
            });
          }
          if (callback) {
            callback();
          }
        }).catch((error) => {
          this.showError(error);
        });
      } else {
        this.userData = this.currentUser;
        if (callback) {
          callback();
        }
      }
    },
    toggleFavorite (userId) {
      if (!this.currentUser) {
        return;
      }

      this.addToFavorites = !this.addToFavorites;
      const url = `${process.env.VUE_APP_BASE_URL}/api/favorites`;
      if (this.addToFavorites) {
        const data = {
          favorite_user_id: userId,
          user_id: this.currentUser.id
        };
        axios.post(url, data).then(() => {
          this.getUserSearched();
        }).catch((error) => {
          this.showError(error);
        });
      } else {
        axios.delete(url, {
          data: {
            favorite_user_id: userId
          }
        }).then(() => {
          this.getUserSearched();
        }).catch((error) => {
          this.showError(error);
        });
      }
    },
    setImportedConstants () {
      this.$options.AES_SECRET = AES_SECRET;
      this.$options.PROFILE_TAB = PROFILE_TAB;
      this.$options.REVIEWS_TAB = REVIEWS_TAB;
      this.$options.PREFERENCES_TAB = PREFERENCES_TAB;
      this.$options.SERVICES_TAB = SERVICES_TAB;
      this.$options.CHAT_TAB = CHAT_TAB;
      this.$options.ORDERS_TAB = ORDERS_TAB;
      this.$options.SUBSCRIPTION_TAB = SUBSCRIPTION_TAB;
      this.$options.CONTACT_TAB = CONTACT_TAB;
      this.$options.CALENDAR_TAB = CALENDAR_TAB;
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
    },
    setTabs () {
      this.tabs = [];
      this.tabs.push({
        key: 'profile',
        index: this.$options.PROFILE_TAB,
        title: 'about',
        enabled: true
      });
      this.tabs.push({
        key: 'reviews',
        index: this.$options.REVIEWS_TAB,
        title: 'reviews',
        enabled: this.$options.JOBS_ENABLED && (this.currentUser && this.currentUser.type_user) >= this.$options.USER_TYPE_PROSKER
      });
      this.tabs.push({
        key: 'preferences',
        index: this.$options.PREFERENCES_TAB,
        title: 'preferences',
        enabled: this.isMyProfile
      });
      this.tabs.push({
        key: 'orders',
        index: this.$options.ORDERS_TAB,
        title: 'services',
        enabled: this.isMyProfile && this.$options.JOBS_ENABLED
      });
      this.tabs.push({
        key: 'services',
        index: this.$options.SERVICES_TAB,
        title: 'myJobs',
        enabled: this.isMyProfile && this.$options.JOBS_ENABLED && (this.currentUser && this.currentUser.type_user) >= this.$options.USER_TYPE_PROSKER
      });
      this.tabs.push({
        key: 'chat',
        index: this.$options.CHAT_TAB,
        title: 'chat',
        enabled: this.isMyProfile
      })
      this.tabs.push({
        key: 'calendar',
        index: this.$options.CALENDAR_TAB,
        title: 'calendar',
        enabled: this.currentUser && ((this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) || (this.$options.JOBS_ENABLED && this.userData.active_subscription.filter(item => item.name === 'Premium' || item.name === 'Free Trial').length > 0))
      });
      this.tabs.push({
        key: 'subscriptions',
        index: this.$options.SUBSCRIPTION_TAB,
        title: 'subscriptions',
        enabled: this.isMyProfile && this.$options.JOBS_ENABLED && (this.currentUser && this.currentUser.type_user >= this.$options.USER_TYPE_PROSKER)
      });
      this.tabs.push({
        key: 'contact',
        index: this.$options.CONTACT_TAB,
        title: 'contact',
        enabled: this.isMyProfile
      });
      this.tabs = this.tabs.sort((a, b) => {
        return (a.index <= b.index) ? -1 : 1;
      }).filter(item => item.enabled);
      if (this.tabs.map(tab => tab.index).includes(this.userSearchedObject.selectedTab)) {
        this.changeTab(this.tabs.find(tab => tab.index === this.userSearchedObject.selectedTab).index);
        this.tabIndex = this.tabs.findIndex(tab => tab.index === this.userSearchedObject.selectedTab);
      } else {
        this.changeTab(this.tabs[0].index);
        this.tabIndex = 0;
      }
    },
    getDateFormat (date) {
      const serverDate = new Date(date);
      const myDate = new Date();
      const difference = myDate - serverDate;
      return moment(moment.utc(difference)).format('D');
    },
    getUserAddress (addresses) {
      if (addresses && addresses.length) {
        let defaultAddress = '';
        addresses.forEach((address) => {
          if (!address.is_default) {
            defaultAddress = address.city.name + ' - ' + address.country;
          }
        });
        return defaultAddress;
      } else {
        return '';
      }
    },
    changeTab (selectedTab) {
      if (this.activeTab === selectedTab) {
        return;
      }
      this.activeTab = selectedTab;
      switch (selectedTab) {
  case this.$options.PROFILE_TAB:
    if (this.$refs.userDetailsComponent && this.$refs.userDetailsComponent[0]) {
      this.$refs.userDetailsComponent[0].sendDataLayerInfo();
    }
    break;
  case this.$options.REVIEWS_TAB:
    if (this.$refs.userReviewsComponent && this.$refs.userReviewsComponent[0]) {
      this.$refs.userReviewsComponent[0].sendDataLayerInfo();
    }
    break;
  case this.$options.PREFERENCES_TAB:
    if (this.$refs.userPreferencesComponent && this.$refs.userPreferencesComponent[0]) {
      this.$refs.userPreferencesComponent[0].sendDataLayerInfo();
    }
    break;
  case this.$options.SERVICES_TAB:
    if (this.$refs.userServicesComponent && this.$refs.userServicesComponent[0]) {
      this.$refs.userServicesComponent[0].sendDataLayerServicesInfo();
    }
    break;
  case this.$options.ORDERS_TAB:
    if (this.$refs.userOrdersComponent && this.$refs.userOrdersComponent[0]) {
      this.$refs.userOrdersComponent[0].sendDataLayerOrdersInfo();
    }
    break;
  case this.$options.CHAT_TAB:
    if (this.$refs.chatComponent && this.$refs.chatComponent[0]) {
      this.$refs.chatComponent[0].sendDataLayerInfo();
    }
    break;
  case this.$options.CALENDAR_TAB:
    if (this.userData.type_user > this.$options.USER_TYPE_CLIENT) {
      if (this.$refs.userCalendarComponent && this.$refs.userCalendarComponent[0]) {
        this.$refs.userCalendarComponent[0].sendDataLayerInfo();
      }
    }
    if (this.$options.USER_TYPE_CLIENT === this.userData.type_user) {
      if (this.$refs.userCalendarClientComponent && this.$refs.userCalendarClientComponent[0]) {
        this.$refs.userCalendarClientComponent[0].sendDataLayerInfo();
      }
    }
    break;
  case this.$options.SUBSCRIPTION_TAB:
    if (this.$refs.userSubscriptionsComponent && this.$refs.userSubscriptionsComponent[0]) {
      this.$refs.userSubscriptionsComponent[0].sendDataLayerInfo();
    }
    break;
  case this.$options.CONTACT_TAB:
    if (this.$refs.contactPageComponent && this.$refs.contactPageComponent[0]) {
      this.$refs.contactPageComponent[0].sendDataLayerInfo();
    }
    break;
}
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onAddCallback () {
      this.resetUserImage();
    },
    newProfileImage () {
      this.showAddImage = true;
    },
    newBanner () {
      this.showDialogUserBanner = true;
    },
    showUserDialog () {
      this.showAdd = true;
    },
    onAddImageClose () {
      this.showAddImage = false;
    },
    onBannerDialogClose () {
      this.showDialogUserBanner = false;
    },
    showButton () {
      if (this.$refs.buttonCamera) {
        this.$refs.buttonCamera.style.marginTop = '0px';
      }
    },
    hideButton () {
      if (this.$refs.buttonCamera) {
        this.$refs.buttonCamera.style.marginTop = '180px';
      }
    },
    scrollToDetails () {
      const tabsSection = this.$refs.tabsSection;
      if (tabsSection) {
        tabsSection.$el.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>
<style scoped>
  .banner-wrapper {
    border-radius: 35px;
    margin-top: 40px;
    overflow: hidden;
  }

  .banner {
    border-radius: 0;
    background-repeat: no-repeat;
  }

  .userContainer {
    margin: -60px 0 0 48px;
    height: 150px;
    width: 150px;
    z-index: 1;
  }

  .userPicture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: solid var(--white) 6px;
    height: 100%;
  }

  .same-line {
    white-space: nowrap;
  }

  .profile-info {
    align-items: flex-end;
    height: 118px;
    margin: 0;
    padding-bottom: 35px;
  }

  .profile-name {
    display: flex;
    flex-direction: column;
    margin-left: 23px;
    justify-content: end;
  }

  .profile-name h2 {
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 12px;
  }

  .profile-work {
    margin-left: 48px;
  }

  .profile-work h5 {
    font-size: 15px;
  }

  .profile-share {
    margin-left: auto;
  }

  .tab {
    padding: 12px 0;
    width: 12%;
  }

  .active-tab {
    color: var(--white);
  }

  .round {
    border-radius: 16px;
  }

  .tab-items-container {
    background-color: transparent;
    margin-top: 72px;
  }

  .bg-275 {
    height: 275px;
  }

  .bg-200 {
    height: 200px;
  }
</style>
<style lang="scss" scoped>
  .sm {
    .tab {
      margin: 0 8px;
      padding: 12px 0.5rem;
    }

    .tab-items-container {
      margin-top: 56px;
    }

    .profile-info {
      align-items: center;
      flex-direction: column;
      gap: 20px;
      height: auto;

      > div {
        margin-left: 0;
      }

      .profile-share {
        margin: auto;

        .button {
          position: static;
        }
      }
    }
  }
</style>
