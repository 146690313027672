<template>
  <div>
    <delete-warning
        :confirm-delete="removeSchedule"
        :on-close="onDeleteClose"
        :show-delete="showDelete"
        :loading="loading"
    />
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        width="800"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <general-button
          size="small"
          secondary
          :action="openDialog"
          v-show="currentUser && currentUser.type_user > 0"
        >
          {{ $t('calendarAvailability') }}
        </general-button>
      </template>
      <v-card>
        <v-card-title class="bg-prosk-secondary white--text">
          <span>{{ $t('calendarAvailability') }}</span>
          <v-btn fab small absolute right @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row class="border-bottom border-primary">
            <v-col class="text-center">
              <span class="font-size-18 black--text">{{ $t("selectDateFree") }}</span>
            </v-col>
          </v-row>
          <v-alert dense type="error" color="red lighten-3" v-if="errors.length" class="my-5">
            <p class="ma-0" v-for="(dayError, index) in errors" :key="`error${index}`">{{ dayError }}</p>
          </v-alert>
          <v-row v-for="(day) in days" :key="day.id" class="my-5">
            <v-col cols="12" v-if="loading">
              <v-skeleton-loader
                  v-for="index in 1"
                  :key="index"
                  v-bind="skeletonAttrs"
                  type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
              ></v-skeleton-loader>
            </v-col>
            <v-row v-if="!loading">
              <v-col md="4" cols="12" class="bg-green-calendar mt-2">
                <label class="col-12 text-center font-weight-bold my-0">
                  {{ $t(day.day).toUpperCase() }}
                </label>
                <div class="d-flex flex-column align-items-center">
                  <v-checkbox
                      v-model="day.closed"
                      :label="$t('closed').toLowerCase()"
                      class="my-0"
                      color="teal"
                      @click="closedDayClicked(day)"
                  ></v-checkbox>
                  <v-btn
                      v-if="!day.closed"
                      class="mx-2"
                      dark
                      color="teal"
                      x-small
                      @click="addNewSchedule(day)"
                  >
                    <v-icon dark x-small>
                      mdi-plus
                    </v-icon>
                    {{ $t('addSchedule') }}
                  </v-btn>
                </div>
              </v-col>
              <v-col md="4" cols="6" v-if="!day.closed">
                <div class="d-flex justify-content-center" v-for="(time, index) in day.times" :key="`start${index}`">
                  <v-col cols="12" :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''">
                    <span class="mr-1 mt-1">{{ $t('beginning') }}</span>
                    <vue-timepicker
                        hide-disabled-items
                        :minute-interval="15"
                        v-model="time.start_time"
                    ></vue-timepicker>
                  </v-col>
                </div>
              </v-col>
              <v-col md="4" cols="6" v-if="!day.closed">
                <div class="d-flex justify-content-center" v-for="(time, index) in day.times" :key="`end${index}`">
                  <v-col cols="10" :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''">
                    <span class="mr-1 mt-1">{{ $t('end') }}</span>
                    <vue-timepicker
                        hide-disabled-items
                        :minute-interval="15"
                        v-model="time.end_time"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2">
                    <v-btn icon x-small @click="openWarning(day, index)" :class="$vuetify.breakpoint.mobile ? 'pt-4 ml-1' : ''">
                      <v-icon dark small color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </div>
              </v-col>
              <v-col md="8" cols="12" v-if="day.closed">
                <v-row align="center" justify="center">
                  <v-col cols="12" class="text-center">
                    <span>{{ $t('dayClosedMessage', {day: $t(day.day)}) }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-button :cancel="closeDialog" :message="$t('cancel')"/>
            <save-button :save="saveAll" :loading="saving" :message="$t('save')"/>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import VueTimepicker from 'vue2-timepicker';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

const moment = extendMoment(Moment);

export default {
  name: 'UserCalendarAvailability',
  mixins: [errorDialog],
  props: [
    'closeDialogFunction'
  ],
  components: { DeleteWarning, GeneralButton, SaveButton, CancelButton, VueTimepicker },
  data () {
    return {
      dialog: false,
      days: [],
      errors: [],
      showDelete: false,
      daySelected: null,
      indexSelected: null,
      loading: true,
      skeletonAttrs: {
        class: '',
        boilerplate: true,
        elevation: 2
      },
      saving: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.getProskerSchedules();
  },
  methods: {
    openWarning (day, index) {
      this.showDelete = true;
      this.daySelected = day;
      this.indexSelected = index;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    closeDialog () {
      this.dialog = false;
    },
    openDialog () {
      this.dialog = true;
    },
    addNewSchedule (day) {
      day.times.push({
        start_time: '09:00',
        end_time: '18:00'
      });
    },
    removeSchedule () {
      if (this.daySelected.times.length > 1) {
        this.daySelected.times = this.daySelected.times.filter((time, i) => i !== this.indexSelected);
        this.onDeleteClose();
      }
    },
    closedDayClicked (day) {
      if (day.closed) {
        day.times = [];
      } else if (!day.closed && day.times.length === 0) {
        this.addNewSchedule(day);
      }
    },
    getProskerSchedules () {
      if (!this.currentUser) {
        return;
      }

      const daysMap = {
        'monday': 1,
        'tuesday': 2,
        'wednesday': 3,
        'thursday': 4,
        'friday': 5,
        'saturday': 6,
        'sunday': 7
      };
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-schedules?filters=prosker_id=${this.currentUser.id}|`;
      axios
          .get(url)
          .then((response) => {
            let schedules = [];
            response.data.data.map(schedule => {
              let daySchedule = schedules.find(item => item.day === schedule.day);
              if (!daySchedule) {
                schedule.times = (schedule.closed) ? [] : [{
                  start_time: schedule.start_time.slice(0, -3), // remove :00 seconds
                  end_time: schedule.end_time.slice(0, -3) // remove :00 seconds
                }];
                schedules.push(schedule);
              } else {
                daySchedule.times.push({
                  start_time: schedule.start_time.slice(0, -3), // remove :00 seconds
                  end_time: schedule.end_time.slice(0, -3) // remove :00 seconds
                });
              }
            });
            this.days = schedules.sort((a, b) => {
              return daysMap[a.day] - daysMap[b.day];
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.response.data.errors;
          });
    },
    dayHasOverlappingTimes (day) {
      // checks if there are overlapping times between the times of a day
      let overlaps = null;
      let i = 0;
      while (!overlaps && i < day.times.length) {
        const time = day.times[i];
        const timeInterval = moment.range(moment('2000-10-10 ' + time.start_time), moment('2000-10-10 ' + time.end_time));
        overlaps = Boolean(day
            .times
            .filter(timeItem => timeItem !== time) // remove iterating time
            .find(intervalItem => {
              const interval = moment.range(moment('2000-10-10 ' + intervalItem.start_time), moment('2000-10-10 ' + intervalItem.end_time));
              return timeInterval.overlaps(interval);
            }));
        i++;
      }
      return overlaps;
    },
    dayHasErrorTimes (day) {
      return day.times.filter(time => time.start_time >= time.end_time).length > 0
    },
    deleteAllSchedules () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-schedules/all`;
      return axios.delete(url);
    },
    getClosedScheduleData (day) {
      return {
        prosker_id: this.currentUser.id,
        day: day.day,
        description: '',
        start_time: null,
        end_time: null,
        closed: 1
      };
    },
    getOpenScheduleData (day) {
      return day.times.map(daySchedule => {
        return {
          prosker_id: this.currentUser.id,
          day: day.day,
          description: '',
          start_time: `${daySchedule.start_time}:00`,
          end_time: `${daySchedule.end_time}:00`,
          closed: 0
        };
      });
    },
    saveAll () {
      this.saving = true;
      const schedulesUrl = `${process.env.VUE_APP_BASE_URL}/api/prosker-schedules`;

      let createScheduleRequestsData = [];
      this.errors = [];
      this.days.forEach(day => {
        let dayScheduleRequests = null;
        if (day.closed) {
          dayScheduleRequests = [this.getClosedScheduleData(day)];
        } else {
          if (this.dayHasOverlappingTimes(day) || this.dayHasErrorTimes(day)) {
            this.errors.push(this.$t('scheduleError', { day: this.$t(day.day).toUpperCase() }));
          }
          dayScheduleRequests = this.getOpenScheduleData(day);
        }

        createScheduleRequestsData = createScheduleRequestsData.concat(dayScheduleRequests);
      });

      if (!this.errors.length) {
        this
            .deleteAllSchedules()
            .then(() => {
              Promise
                  .all(createScheduleRequestsData.map(data => axios.post(schedulesUrl, data)))
                  .then((responses) => {
                    responses.forEach((resp) => {
                      let msg = {
                        server: resp.headers.server,
                        status: resp.status,
                        fields: Object.keys(resp.data).toString()
                      };
                      console.info(resp.config.url);
                      console.table(msg);
                    });
                    this.saving = false;
                    this.dialog = false;
                    this.$emit('close-availability-dialog', { reloadEvents: true });
                  })
                  .catch((error) => {
                    this.saving = false;
                    this.errors = error.response.data.errors;
                  });
            })
            .catch((error) => {
              this.saving = false;
              this.errors = error.response.data.errors;
            });
      } else {
        this.saving = false;
      }
    }
  }
}
</script>
