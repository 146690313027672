<template>
  <v-alert dense type="info" color="title_highlight_color" class="my-2">
    <p class="ma-0">{{ message }}</p>
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: ['message']
};
</script>
