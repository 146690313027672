<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveSkills"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" class="removeHover" icon color="title_highlight_color" v-on="on">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline"> {{ $t("AddSkillToUser") }} </span>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" class="mt-3">
          <v-autocomplete
            :items="skills"
            v-model="skillsSelected"
            chips
            color="#59D79D"
            :error-count="errors.length"
            :error-messages="errors"
            item-color="#59D79D"
            item-text="skill_name"
            item-value="id"
            multiple
            solo
            :search-input.sync="searchInput"
            @change="searchInput = ''"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.skill_name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <template #selection="{ item }">
              <v-chip color="#59D79D" class="white--text">{{item.skill_name}}</v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="saveSkills" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import _debounce from 'lodash/debounce';

import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'AddSkillsToUser',
  components: { CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'user', 'newItem'],
  mixins: [errorDialog],
  data () {
    return {
      skillsSelected: [],
      errors: [],
      searchInput: '',
      isLoading: false,
      skills: [],
      loading: false
    };
  },
  created () {
    this.getSkillsData();
    this.getUserSkills();
  },
  watch: {
    'searchInput': function () {
      this.getSkillsData(this);
    }
  },
  methods: {
    getUserSkills () {
      this.user.skills.forEach((skill) => {
        this.skillsSelected.push(skill);
      });
    },
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.skillsSelected = [];
      this.getUserSkills();
      this.newItem();
    },
    getSkillsData: _debounce(function () {
      this.isLoading = true;
      const url = (process.env.VUE_APP_BASE_URL + `/api/skills?search=${this.searchInput === null
          ? '' : this.searchInput}&page=1`).trim();
      axios.get(url).then((response) => {
        this.skills = response.data.data;
        this.isLoading = false;
      })
      .catch((error) => {
        this.showError(error);
        this.isLoading = false;
      });
    }, 500),
    saveSkills () {
      this.errors = [];
      this.loading = true;
      if (this.skillsSelected === null) {
        this.errors.push(this.$t('fieldCategoryRequired'));
      }
      const url =
        process.env.VUE_APP_BASE_URL + '/api/users/' + this.user.id + '/skills';
      const data = {
        user_id: this.user.id,
        skill_ids: this.skillsSelected
      };
      axios
        .patch(url, data)
        .then(() => {
          this.updateUser(this.user.id);
          this.updatePercentage(this.user.id);
          this.onClose();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    }
  }
};
</script>
