<template>
  <div>
    <delete-warning
        :confirm-delete="removeSpecialDate"
        :on-close="onDeleteClose"
        :show-delete="showDelete"
        :loading="loading"
    />
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        width="800"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <general-button
          size="small"
          outlined
          :action="openDialog"
          v-show="currentUser && currentUser.type_user > 0"
        >
        {{ $t('calendarSpecialDates') }}
        </general-button>
      </template>
      <v-card>
        <v-card-title class="bg-prosk-secondary white--text">
          <span>{{ $t('calendarSpecialDates') }}</span>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row class="border-bottom border-primary">
            <v-col class="text-center">
              <span class="font-size-18 black--text">{{ $t("selectSpecialDateFree") }}</span>
              <v-btn fab small absolute right @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-alert dense type="error" color="red lighten-3" v-if="specialDatesErrors.length" class="my-5">
            <p class="ma-0" v-for="(specialDatesError, index) in specialDatesErrors" :key="`error${index}`">
              {{ specialDatesError }}</p>
          </v-alert>
          <div v-for="(specialDate, index) in specialDates" :key="`date${index}`" class="my-5">
            <div v-if="loading">
              <v-skeleton-loader
                  v-for="index in 1"
                  :key="index"
                  v-bind="skeletonAttrs"
                  type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
              ></v-skeleton-loader>
            </div>
            <div v-if="!loading">
              <div>
                <v-text-field
                    :label="$t('date')"
                    type="date"
                    v-model="specialDate.special_date"
                ></v-text-field>
              </div>
              <div>
                <div class="special-date-form">
                  <div>
                    {{ $t('beginning') }}
                    <vue-timepicker
                        hide-disabled-items
                        :minute-interval="15"
                        v-model="specialDate.start_time"
                    ></vue-timepicker>
                  </div>
                  <div>
                    {{ $t('end') }}
                    <vue-timepicker
                        hide-disabled-items
                        :minute-interval="15"
                        v-model="specialDate.end_time"
                    ></vue-timepicker>
                  </div>
                  <div class="options">
                    <v-checkbox
                        v-model="specialDate.closed"
                        :label="$t('closed').toLowerCase()"
                        color="teal"
                        @click="closedSpecialDateClicked(specialDate)"
                    ></v-checkbox>
                    <v-btn icon small @click="openWarning(index)" class="mt-3 ml-4">
                      <v-icon dark small color="red">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-row class="mb-4" v-if="!loading">
            <v-col cols="3">
              <v-btn class="white--text" small block color="teal" @click="addSpecialDate">
                <v-icon left> mdi-calendar</v-icon>
                <span class="ms-1">{{ $t('addDate') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <cancel-button :cancel="closeDialog" :message="$t('cancel')"/>
            <save-button :save="saveAll" :loading="saving" :message="$t('save')"/>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import VueTimepicker from 'vue2-timepicker';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning.vue';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';

const moment = extendMoment(Moment);

export default {
  name: 'UserCalendarSpecialDates',
  components: { DeleteWarning, GeneralButton, SaveButton, CancelButton, VueTimepicker },
  data () {
    return {
      dialog: false,
      showDelete: false,
      indexSelected: null,
      specialDates: [],
      specialDatesErrors: [],
      loading: true,
      skeletonAttrs: {
        class: '',
        boilerplate: true,
        elevation: 2
      },
      saving: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.getProskerSpecialDates();
  },
  methods: {
    openWarning (index) {
      this.showDelete = true;
      this.indexSelected = index;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    closeDialog () {
      this.dialog = false;
    },
    openDialog () {
      this.dialog = true;
    },
    closedSpecialDateClicked (specialDate) {
      if (specialDate.closed) {
        specialDate.start_time = '';
        specialDate.end_time = '';
      }
    },
    removeSpecialDate () {
      this.specialDates = this.specialDates.filter((date, i) => i !== this.indexSelected);
      this.onDeleteClose();
    },
    addSpecialDate () {
      this.specialDates.push({
        start_time: '',
        end_time: '',
        special_date: '',
        closed: false,
        prosker_id: this.currentUser.id
      });
    },
    getMockSpecialDates () {
      return [
        {
          start_time: '',
          end_time: '',
          special_date: '',
          closed: false,
          prosker_id: this.currentUser.id
        }
      ]
    },
    getProskerSpecialDates () {
      if (!this.currentUser) {
        return;
      }

      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-special-dates?filters=prosker_id=${this.currentUser.id}|`;
      axios
          .get(url)
          .then((response) => {
            this.specialDates = response.data.data.map(item => {
              item.start_time = (item.start_time) ? item.start_time.slice(0, -3) : ''; // remove :00 seconds
              item.end_time = (item.end_time) ? item.end_time.slice(0, -3) : ''; // remove :00 seconds
              return item;
            });
            if (!response.data.data.length) {
              this.specialDates = this.getMockSpecialDates();
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.specialDatesErrors = error.response.data.errors;
          });
    },
    specialDateHasOverlappingTimes (specialDate) {
      // checks if there are overlapping times between the times of a special date
      let overlaps = null;
      let i = 0;
      while (!overlaps && i < this.specialDates.length) {
        const timeInterval = moment.range(moment('2000-10-10 ' + specialDate.start_time), moment('2000-10-10 ' + specialDate.end_time));
        overlaps = Boolean(
            this.specialDates
                .filter(item => item !== specialDate) // remove iterating specialDate
                .filter(item => item.special_date === specialDate.special_date) // only check within same date
                .find(intervalItem => {
                  const interval = moment.range(moment('2000-10-10 ' + intervalItem.start_time), moment('2000-10-10 ' + intervalItem.end_time));
                  return timeInterval.overlaps(interval);
                }));
        i++;
      }
      return overlaps;
    },
    hasAnotherOpenInstance (specialDate) {
      return this.specialDates
          .filter(item => item !== specialDate)
          .filter(item => item.special_date === specialDate.special_date && !item.closed)
          .length > 0;
    },
    deleteAllSpecialDates () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-special-dates/all`;
      return axios.delete(url);
    },
    validSpecialDate (specialDate) {
      return ((specialDate.closed && !this.hasAnotherOpenInstance(specialDate)) || (!specialDate.closed && specialDate.start_time < specialDate.end_time && !this.specialDateHasOverlappingTimes(specialDate)));
    },
    adaptSpecialDate (specialDate) {
      return {
        prosker_id: this.currentUser.id,
        special_date: specialDate.special_date,
        description: '',
        start_time: (specialDate.closed) ? '' : `${specialDate.start_time}:00`,
        end_time: (specialDate.closed) ? '' : `${specialDate.end_time}:00`,
        closed: +specialDate.closed
      };
    },
    saveAll () {
      const specialDatesUrl = `${process.env.VUE_APP_BASE_URL}/api/prosker-special-dates`;
      this.saving = true;
      this.specialDatesErrors = [];
      this.specialDates.forEach(specialDate => {
        if (!this.validSpecialDate(specialDate)) {
          this.specialDatesErrors.push(this.$t('scheduleError', { day: this.$t(specialDate.special_date) }));
        }
      });

      if (!this.specialDatesErrors.length) {
        this
            .deleteAllSpecialDates()
            .then(() => {
              Promise
                  .all(this.specialDates.map(data => axios.post(specialDatesUrl, this.adaptSpecialDate(data))))
                  .then((responses) => {
                    responses.forEach((resp) => {
                      let msg = {
                        server: resp.headers.server,
                        status: resp.status,
                        fields: Object.keys(resp.data).toString()
                      };
                      console.info(resp.config.url);
                      console.table(msg);
                    });
                    this.dialog = false;
                    this.saving = false;
                    this.$emit('close-availability-dialog', { reloadEvents: true });
                  })
                  .catch((error) => {
                    this.saving = false;
                    this.specialDatesErrors = error.response.data.errors;
                  });
            })
            .catch((error) => {
              this.saving = false;
              this.specialDatesErrors = error.response.data.errors;
            });
      } else {
        this.saving = false;
      }
    }
  }
}
</script>
<style scoped>
  .special-date-form {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-around;
    align-items: center;
  }

  .options {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  @media(max-width: 428px) {
    .special-date-form {
      flex-direction: column !important;
    }
  }
</style>
